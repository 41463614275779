<template>
  <!-- https://developers.google.com/chart/interactive/docs/points -->

  <!-- <button class="btn btn-primary" @click="showFilter=!showFilter" style="width: 100%">Filter anzeigen</button> -->

  <div>
          <el-button type="success" class="w100" icon="el-icon-search" @click="onReload">Daten neu laden</el-button>
          <el-button type="success" class="w100" icon="el-icon-back" @click="setNextCurrentLocation(-1)"></el-button>
          <el-button type="success" class="w100" icon="el-icon-right" @click="setNextCurrentLocation(1)"></el-button>
  </div>

<!-- {{locationList}} -->
<!-- currentLocation: {{currentLocation}}<br>
myloc {{myLoc}}<br> -->
<input type="hidden" v-shortkey="['arrowleft']" @shortkey="setNextCurrentLocation(-1)"/>
<input type="hidden" v-shortkey="['arrowright']" @shortkey="setNextCurrentLocation(1)"/>

<el-select  v-model="currentLocation"   @change="setChartData()"   placeholder="Locations"      filterable      style="width: 100%"    >
              <el-option        v-for="location in locationList"        :key="location"        :label="location"        :value="location"      ></el-option>
            </el-select>

          <!-- <el-button :key="location" v-for="location of locationList" type="success"  icon="el-icon-search" @click="onReload">{{location}}</el-button> -->
<!-- <button :key="location" v-for="location of locationList">{{location}}</button> -->
  <div v-show="loading===true" style="text-align:center;margin-top:20px;" class="red bold">
    Bitte warten. Es werden Daten vom Server geladen
  </div>
  

  <GChart type="ColumnChart" :data="chartData[0]" :options="chartOptions[0]"  />
  <div v-show="chartData[0].length == 0 && loading==false" style="text-align:center;margin-top:20px">
    Für diese Parameter wurden keine Datensätze gefunden
  </div>


<!-- </div> -->

</template>
<script>
  
  import { ref,onMounted,inject} from 'vue'
export default {
  
  setup(){
    const web=inject("web")
    // const storage =inject("storage")
    const moment =inject("moment")

    let loading =ref(false);
    let showFilter =ref(false);
    var timeTables=ref({})



    let locationList = ref([])

  let currentLocation=ref("Adieu Paris - Döner Cherie")
    function setCurrentLocation(location){
      console.log("setCurrentLocation")
      console.log(location)
      currentLocation.value=location;
      setChartData()
    }

    
    // let  chartData =  ref([])
    let  chartData =  ref([[
          ['Month', 'Anz'],
          ['2004/05',  0],
        ]])

  let chartOptions = [{
 
        title : currentLocation.value,
        seriesType: 'bars',
        pointShape: 'circle',
        pointSize: 5,
        height:800,
        width:"100%",
      }]








        var dateFromObjectId = function (objectId) {
          if(typeof(objectId)=="undefined"){
            return "";
            }
          // return moment(parseInt(objectId.substring(0, 8), 16) * 1000).format("YYYYMMDD-HH");

          return moment(parseInt(objectId.substring(0, 8), 16) * 1000).format("DD-HH");
        };



          
        function onReload(){
           loading.value=true;
            timeTables.value={}
           web.post("/scans",[],function(data){


            //  console.log(data);
              var checkins={}
              // let ll=locationList.value
             for (let i = 0; i < data.scans.length; i++) {
               const scan = data.scans[i];
                if(typeof(timeTables.value[scan.location])=="undefined"){
                  console.log("init: "+scan.location)
                  if(locationList.value.indexOf(scan.location)==-1){
                    locationList.value.push(scan.location)
                  }
                  timeTables.value[scan.location]={
                    "20210907-08":0,
                    "20210907-09":0,
                    "20210907-10":0,
                    "20210907-11":0,
                    "20210907-12":0,
                    "20210907-13":0,
                    "20210907-14":0,
                    "20210907-15":0,
                    "20210907-16":0,
                    "20210907-17":0,
                    "20210907-18":0,
                    "20210907-19":0,
                    "20210907-20":0,
                    "20210907-21":0,
                    "20210907-22":0,
                    "20210908-08":0,
                    "20210908-09":0,
                    "20210908-10":0,
                    "20210908-11":0,
                    "20210908-12":0,
                    "20210908-13":0,
                    "20210908-14":0,
                    "20210908-15":0,
                    "20210908-16":0,
                    "20210908-17":0,
                    "20210908-18":0,
                    "20210908-19":0,
                    "20210908-20":0,
                    "20210908-21":0,
                    "20210908-22":0,
                    "20210908-23":0,
                    }

                     timeTables.value[scan.location]={
                    "07-08":0,
                    "07-09":0,
                    "07-10":0,
                    "07-11":0,
                    "07-12":0,
                    "07-13":0,
                    "07-14":0,
                    "07-15":0,
                    "07-16":0,
                    "07-17":0,
                    "07-18":0,
                    "07-19":0,
                    "07-20":0,
                    "07-21":0,
                    "07-22":0,
                    "08-08":0,
                    "08-09":0,
                    "08-10":0,
                    "08-11":0,
                    "08-12":0,
                    "08-13":0,
                    "08-14":0,
                    "08-15":0,
                    "08-16":0,
                    "08-17":0,
                    "08-18":0,
                    "08-19":0,
                    "08-20":0,
                    "08-21":0,
                    "08-22":0,
                    "08-23":0,
                    }
                }

                
                 //print(scan)
                if(typeof(checkins[scan.hash])=="undefined"){
                  let stundenSlot = dateFromObjectId(scan._id);
                  // if(false){
                    // console.log(stundenSlot)
                    if(typeof(timeTables.value[scan.location][stundenSlot])!=="undefined"){

                      timeTables.value[scan.location][stundenSlot]++;
                    }
                  // }
                    // console.log(scan.hash+" IN  "+dateFromObjectId(scan._id)+" "+scan.location);
                    checkins[scan.hash]=scan
                }else{
                    // print(scan._id+" OUT");
                    // console.log(scan.hash+" OUT "+dateFromObjectId(scan._id)+" "+scan.location);
                    delete checkins[scan.hash]

                    

                }
                
             }
             locationList.value.sort()

            // let currentLocation="Wilma Wunder";
            setChartData()
             loading.value=false;
            //  console.log(data)
            //  chartData.value =checkForMissingDays(data.chartdata);
            //  
            //  chartData.value =data.chartdata;
            //  chartData2.value =data.chartdata;
           })
        }


function setChartData(){

  chartOptions[0].title=currentLocation.value
let myArray={}
             for (const [key, value] of Object.entries(timeTables.value)) {
              //  console.log(`${key}: ${value}`);
              // if(key!==currentLocation.value) continue

              if(typeof(myArray[key])=="undefined"){
                // console.log("lege Array an")
                myArray[key]=[["Stunde","Anz"]]
              }
              for (const [date_key, visitors] of Object.entries(value)) {

                    // console.log("keyx",key)
                    // console.log("date key",date_key)
                    // console.log("visitors",visitors)
                    
                    if(key==currentLocation.value){
                      myArray[key].push([date_key,visitors])
                      // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",myArray)
                    }
                }
              }
              console.log("myArray",myArray)

              chartData.value[0]=myArray[currentLocation.value]
              // console.log("chartData",chartData.value)

             
}
        onMounted(() => {
          

          onReload()
        })

   

  const querySearchAsync = (queryString, cb) => {
        
    console.log("queryString")
    console.log(queryString)
    web.post("/promoterSearch",{name:queryString},function(data){
      console.log(data)
      cb(data.promoter);
    })
  };


function setNextCurrentLocation(step){
  console.log("setNextCurrentLocation")
  console.log(step)
  console.log(locationList.value)

  let index = locationList.value.indexOf(currentLocation.value)
  console.log("index: "+index);
  let newIndex=index+step;
  if(newIndex==-1){
    locationList.value.length-1;
  }

  if(newIndex>locationList.value.length-1){
    newIndex=0;
  }
  currentLocation.value=locationList.value[newIndex];
  setChartData()

  
}


    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
     chartOptions,
     querySearchAsync,
     chartData,
     onReload,
     showFilter,
     loading,
     timeTables,
     locationList,
     currentLocation,
     setCurrentLocation,
     setChartData,
     setNextCurrentLocation
      
    };
  }
}
</script>

<style scoped>

</style>