<template>
  <div class="main">
    <!-- <router-link class="nav-link" to="/qrscanner"  tag="button">Jetzt QR Code Scannen</router-link>  -->

    <div
      v-if="queuedScans !== null && queuedScans.length > 0"
      style="font-weight: bold; color: red"
    >
      Achtung. Es liegen {{ queuedScans.length }} Scans in der Warteschlange. Du
      hast vielleicht keine Verbindung zum Internet.
      <button         @click="emptyQueue()"        style="width: 99%; height: 100px"        class="btn btn-secondary"      >
        Warteschlage abschicken
      </button>
    </div>


    <button @click="goTo('/qrscanner')" style="width: 99%;height: 100px;position: fixed;bottom: 30px;left: 0px;" class="btn btn-success">
      Scan
    </button>

    <button  @click="goTo('/login')" style="width: 99%;height: 100px;position: fixed; bottom: 230px;left: 0px;" class="btn btn-success">
      Login
    </button>
   <button  @click="goTo('/locations')" style="width: 99%;height: 100px;position: fixed; bottom: 130px;left: 0px;" class="btn btn-success">
      Locations
    </button>

    
  </div>
</template>
<script>
import { inject, ref } from "vue";
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  setup() {
    const storage = inject("storage");
    console.log("STUP");
    let queuedScans = ref([]);

    function init() {
      console.log("init");
      setTimeout(() => {
        queuedScans.value = storage.get("queue");
      }, 1000);
    }

    init();

    function goTo(target) {
      router.push({ path: target });
    }

    function emptyQueue() {
      storage.emptyQueue(function (data) {
          if(data.success===true){

              queuedScans.value=[]
          }
      });
    }

    return {
      goTo,
      queuedScans,
      emptyQueue,
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>