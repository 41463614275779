<template>
  <div class="main">
    

<div>

    <button @click="reloadData()"  class="btn btn-success">      Neu laden    </button>
</div>
    <div class="location" :key="location.location" v-for="location in locations" @click="nullen(location)">
      <div class="locationHeader">{{location.location}}</div>
      <div class="locationFooter">{{location.limits.visitors}} / {{location.limits.limit}}</div>
      <div class="locationBar"  :style="getBarWidth(location)"></div>
    </div>
<!-- 
    <table class="table table-striped">

      <tr>
        <th>Location</th>
        <th>Gäste</th>
      </tr>
      <tr :key="location.location" v-for="location in locations">
        <td>{{location.location}}</td>
        <td>{{location.limits.anz}} / {{location.limits.limit}}</td>
      </tr>
    </table> -->
    
  </div>
</template>
<script>
import { inject, ref,onMounted } from "vue";

export default {
  components: {},

  setup() {
    // const storage = inject("storage");
    const web = inject("web");
    const socket = inject("socket");
    let locations = ref([]);

   socket.on("pleaseRefreshData",() => {
      // console.log("pleaseRefreshData",newdata)
      reloadData()
    })


    function reloadData() {
      web.post("/statistik",{},function(data){
        locations.value=data.locations
      })
    }

    function getBarWidth  (location) {
      // console.log(location.limits.anz)
      // console.log(location.limits.limit)
      // console.log(location.limits.limit)
      // console.log(location.limits.anz)

    let width=(location.limits.visitors/location.limits.limit)*150;


    let backgroundColor="green"
      if(width>120){
        backgroundColor="orange"
      }
      if(width>145){
        backgroundColor="red"
      }

      if(width>150){
        width=150;
      }
      
      // console.log("------------"+width)
      return "width:"+width+"px;background-color:"+backgroundColor
    }

  
  function  nullen (location){
    console.log(location.limits.visitors);
    var newVisitors=prompt("Bitte anzahl angeben",location.limits.visitors)
    console.log(newVisitors)
      // if(!confirm("Sicher?")){
      //   return;
      // }
      web.post("/nullen",{location:location,newVisitors:newVisitors},function(data){
        console.log(data)
        reloadData()
      })
  }


    onMounted(() => {
      reloadData()
    })



    return {
      reloadData,
      locations,
      getBarWidth,
      nullen
    };
  }
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}

.locationHeader{
  overflow:hidden;
  font-weight:bold;
  font-size:10px;
  
}
.location{
  text-align:center;
  cursor:pointer;;
  position:relative;
  float:left;
  border:1px solid black;
  margin:3px;
  width:150px;
  height:60px;
}
.locationBar{
  position:absolute;
  bottom:0px;
  height:10px;
  background-color:green
}
</style>