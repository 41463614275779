<template>
  <div>
    <!-- <button @click="reloadData">Neu laden</button> -->
    <!-- <qrcode-stream @decode="onDecode" @init="onInit" ></qrcode-stream>
<qrcode-drop-zone></qrcode-drop-zone>
<qrcode-capture></qrcode-capture>
 -->
    <qrcode-stream :camera="camera" :track="paintOutline"></qrcode-stream>
    <!-- <qrcode-capture @decode="onDecode" class="mb"></qrcode-capture> -->
    <div v-show="showScanConfirmation" class="scan-confirmation" style="">
      <img
        src="@/assets/checkmark.svg"
        alt="Checkmark"
        width="128px"
        style="position:absolute;left;0px;top:100px;width:200px;height:200px"
      />
    </div>
    <div class="limitAnzeige" style="" v-bind:class="{besetzt:gaeste >= limit}" @click="reloadData">
      {{gaeste}} / {{limit}}
    </div>

    <button
      @click="decrease"
      style="
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 120px;
        left: -5px;
      "
      class="btn btn-danger"
    >
      Person auschecken
    </button>

    <button
      v-if="camera == 'off'"
      @click="setCamera('rear');ausgabe=''"
      style="
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 20px;
        left: -5px;
      "
      class="btn btn-success"
    >
      Jetzt scannen
    </button>
    <div style="border: 0px solid black; height: 300px;font-weight:bold;font-size:16px;color:red">
      {{ ausgabe }}
    </div>
  </div>
</template>

<script>
// https://www.npmjs.com/package/qrcode-vue3
// import QRCodeVue3 from "qrcode-vue3";
import { inject, ref, onMounted } from "vue";
// import h from "@/services/helpers";
import router from "../router";
export default {
  name: "XXX",
  components: {},
  setup() {

    


    async function sha256(message) {
      const msgBuffer = new TextEncoder().encode(message);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    }

    const moment = inject("moment");
    const storage = inject("storage");
    const socket = inject("socket");
    const web = inject("web");
    let qrData = ref("");
    let ausgabe = ref("");
    // const notify = inject("notify");
    let myTimeout = null;
    let myCameraOffTimeout = null;
    let camera = ref("rear");
    let settings = {};
    let user = {};
    let locations= ref([{      anz:0    }]);
    let limit= ref();
    let gaeste= ref();

    let showScanConfirmation = ref(false);

    let lastDetectedCode = null;

    socket.on("pleaseRefreshData",() => {
      // console.log("pleaseRefreshData"',newdata)
      reloadData()
    })


    function decrease() {
      // console.log("decrease");
      addToQueue({decrease:true,location:storage.get("user").location})
    }
    function reloadData() {
      web.post("/myStatistik",{location:storage.get("user").location},function(data){
        gaeste.value=data.gaeste;
        limit.value=data.limit;
      })
    }


    async function codeDetected(data) {
      // data="https://guest.undo-app.de/checkin?location=64459c8f119512e58c1e3c50f0b68fd3";

      if (myCameraOffTimeout !== null) {
        clearTimeout(myCameraOffTimeout);
      }

      if (settings.timeout > 0) {
        myCameraOffTimeout = setTimeout(() => {
          camera.value = "off";
          ausgabe.value = "";
        }, settings.timeout);
      }
      // camera.value="off"
      // https://gruhn.github.io/vue-qrcode-reader/demos/CustomTracking.html
      // Wenn der Code lgeich ist, sperre ich ihn für ein paar Sekunden ()
      if (lastDetectedCode === data) {
        if (myTimeout == null) {
          myTimeout = setTimeout(() => {
            lastDetectedCode = null;
            myTimeout = null;
            console.log("Release Code");
          }, 5000);
        }
        // console.log("Habe schon einen Code")
        return;
      }
      clearInterval(myTimeout);
      lastDetectedCode = data;

      // console.log(camera)

      // if (        settings.codelength > 0 &&        (data.length != settings.codelength || !h.is_numeric(data))      ) {
      // if (data.length != 16 || !h.is_numeric(data)){
      //   ausgabe.value = "Dieser  Code ist ungültig: ";
      //   camera.value = "off";
      //   return;
      // }

      showScanConfirmation.value = true;
      setTimeout(() => {
        showScanConfirmation.value = false;
      }, 1500);



      console.log("onDecode");
      console.log(data);
      qrData.value = data;

      let hash = await sha256(data);
      var mydata = {
        hash: hash,
        location: user.location,
        created: moment(),
      };

      addToQueue(mydata);
    }

    function paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        codeDetected(detectedCode.rawValue);

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    }

    function setCamera(was) {
      camera.value = was;

      setTimeoutForCamera();
    }

    function addToQueue(object) {
      let queue = storage.get("queue");
      if (queue == null) {
        queue = [];
      }
      queue.push(object);
      storage.set("queue", queue);
      storage.emptyQueue(function () {
      });
      // camera-.value=of
    }

    onMounted(() => {
      reloadData()
      console.log("MOUNTED")
      settings = storage.get("settings");
      if(settings==null){
        settings = {
          codelength:0,
          timeout:20000
        }
        storage.set("settings",settings);
      }
  
      
      user = storage.get("user");
      console.log("user",user)
      console.log("settings",settings)
      setTimeoutForCamera();
    });

    function setTimeoutForCamera() {
      if (settings.timeout > 0) {
        myCameraOffTimeout = setTimeout(() => {
          camera.value = "off";
          ausgabe.value = "";
        }, settings.timeout);
      }
    }

    function goTo(target) {
      router.push({ path: target });
    }

    return {
      qrData,
      setCamera,
      goTo,
      camera,
      paintOutline,
      showScanConfirmation,
      ausgabe,
      locations,
      gaeste,
      limit,
      reloadData,
      decrease

    };
  },
};
</script>


<style scoped>



.limitAnzeige{
  position: absolute;
    right: 10px;
    border-radius: 5px;
    top: 90px;
    z-index: 33322;
    background-color: #a0ffb0;
    color: #000;
    padding: 5px;
}

.besetzt{
  background-color:#FF0000;
  color:#FFF;
  
}

.scan-confirmation {
  z-index: 22;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 350px;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>