<template>
    <div >
        <div class="row m-1">
            <div class="col-md-12">
                <label >Code Länge überprüfen (0 für keine Prüfung)</label>
                <input type="text" class="form-control"  v-model="settings.codelength">
            </div>
        </div>
        <div class="row m-1">
            <div class="col-md-12">
                <label >Timeout für den Scanner</label>
                <input type="text" class="form-control"  v-model="settings.timeout">
            </div>
        </div>
        <div class="row m-1">
            <div class="col-md-12">
                
                <button type="button" @click="saveSettings()" class="btn btn-primary">Speichern</button>
            </div>
        </div>
    </div>   
    
    
</template>






<script>


import { inject,ref,onMounted } from 'vue'
import router from "../router";
export default{
    setup(){
        
        // const web =inject("web")
        const storage =inject("storage")
        // const store = useStore()

        let  settings =ref({
                codelength:0,
                timeout:20000
            })
         function saveSettings(){

            storage.set("settings",settings.value)

        router.push({ path: "/qrscanner" });
        }
        
            onMounted(() => {
                settings.value = storage.get("settings")
            });

        return {
            settings,
            saveSettings
            }
    
    }
}












</script>

<style scoped>

.btn{
    margin-left:0px;
    width:100%
}
</style>