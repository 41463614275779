import {createRouter,createWebHistory} from 'vue-router' 
// import {inject} from 'vue' 

//  import Test from '../views/Tets'
 import Heatmap from '../views/Heatmap'
 import Charts from '../views/Charts'
 import Locations2 from '../views/Locations2'
 import Locations from '../views/Locations'
 import Qrcreator from '../views/Qrcreator'
 import Settings from '../views/Settings'
 import Qrscanner from '../views/Qrscanner'
 import Printer from '../views/Printer'
 import Login from '../views/Login'
 import Home from '../views/Home'
//  import h from '@/plugins/helperPlugin'
 import h from '@/services/helpers'
import  storage from  "@/services/storage"

 const routes = [
   {
      path:"/charts",
      name:"Charts",
      component:Charts,
      meta: { requiresAuth: true },
   },{
      path:"/heatmap",
      name:"Heatmap",
      component:Heatmap,
      meta: { requiresAuth: true },
   },
     {
        path:"/printer",
        name:"Printer",
        component:Printer,
        meta: { requiresAuth: true },
     },
     {
        path:"/settings",
        name:"Settings",
        component:Settings,
        meta: { requiresAuth: true },
      },
     {
        path:"/qrcreator",
        name:"Qrcreator",
        component:Qrcreator,
        meta: { requiresAuth: true },
     },
     {
        path:"/qrscanner",
        name:"Qrscanner",
        component:Qrscanner,
        meta: { requiresAuth: true },
     }, {
      path:"/locations",
      name:"Locations",
      component:Locations,
      meta: { requiresAuth: true },
   }, {
    path:"/locations2",
    name:"Locations2",
    component:Locations2,
    meta: { requiresAuth: true },
 },
     {
        path:"/",
        name:"Startseite",
        component:Home,
        meta: { requiresAuth: false },
     },
     {
        path:"/login",
        name:"login",
        component:Login,
        meta: { requiresAuth: false },
     }
     
 ]

// let isLoggedIn = function(){

//      console.log("isLoggedIn")
//      let user = localStorage.getItem("user");
//      if(user!==null && typeof(user)!="undefined"){
//          return true;
//      }
 
//      return false;
//  }

 const router = createRouter({
     history:createWebHistory(process.env.BASE_URL),
     routes,
    })
    router.replace({ path: '*', redirect: '/abgleichmaerkte' })

    router.beforeEach((to, from, next) => {

     // console.log("beforeEach",to)
     if (to.matched.some(record => record.meta.requiresAuth_promoter)) {
          console.log("Yeachh. I neede a promoter auth")

          // const storage =inject("storage")
          console.log('storage.get("user"');
          if(storage.get("promoter") ==  null){

               next({ name: 'Login' })
          }else{
               next()
          }
     }


     if (to.matched.some(record => record.meta.requiresAuth)) {
       // this route requires auth, check if logged in
       // if not, redirect to login page.

     //   console.log("123")
       if (!h.isLoggedIn()) {
         next({ name: 'Login' })
       } else {
         next() // go to wherever I'm going
       }
     } else {
       next() // does not require auth, make sure to always call next()!
     }
   })

    export default router