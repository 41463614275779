<template>
  <div class="main">
    

    <button @click="reloadData()"  class="btn btn-success">      Neu laden    </button>

    <table class="table table-striped">

      <tr>
        <th>Location</th>
        <th>Gäste</th>
      </tr>
      <tr :key="location.location" v-for="location in locations">
        <td>{{location.location}}</td>
        <td>{{location.limits.visitors}} / {{location.limits.limit}}</td>
      </tr>
    </table>
    
  </div>
</template>
<script>
import { inject, ref,onMounted } from "vue";

export default {
  components: {},

  setup() {
    // const storage = inject("storage");
    const web = inject("web");
    const socket = inject("socket");
    let locations = ref([]);

   socket.on("pleaseRefreshData",() => {
      // console.log("pleaseRefreshData",newdata)
      reloadData()
    })


    function reloadData() {
      web.post("/statistik",{},function(data){
        locations.value=data.locations;
      })
    }


    onMounted(() => {
      reloadData()
    })



    return {
      reloadData,
      locations
    };
  }
};
</script>

<style scoped>
.main {
  margin: 10px;
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
template {
  padding: 20px;
}
.main {
  margin: 20px;
}
</style>