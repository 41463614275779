<template>
  <center>
  <button @click="walkCurrentStunde(-1)" class="btn btn-primary">-1 </button>
    {{getStundeForCurrentStunde()}}
  <button  @click="walkCurrentStunde(1)" class="btn btn-primary">+1</button>
  </center>
  <div id="map" style="width:100%;height:600px;"></div>

<!-- <div>
  {{currentStunde}}
</div> -->
</template>
<script>
import gmapsInit from '../utils/gmaps';

import { ref ,inject, onMounted} from 'vue'
export default {
  
  // https://github.com/fawmi/vue-google-maps

  

  setup(){
    const web=inject("web")
    let data=ref([])
    console.log(data)
    let heatmap=null;

    let google = null;
    onMounted(() => {
      setTimeout(() => {

        addPoints()
      },100)
    })

let zoom=15;
let center={ lat:50.9408, lng: 6.94068 };
let map=null;
async function initMap() {



  if(map==null){

    map = new google.maps.Map(document.getElementById("map"), {
      zoom: zoom,
    center: center,
    mapTypeId: "satellite",
  });
    }
    if(heatmap!==null){

      heatmap.setMap(null)
    }
  heatmap = new google.maps.visualization.HeatmapLayer({
    data: points,
    map: map,
    radius:20,
    // dissipating:false
  });


  google.maps.event.addListener(map, 'zoom_changed', function() {
      let zoomLevel = map.getZoom();
      zoom=zoomLevel
    });
  google.maps.event.addListener(map, 'center_changed', function() {
      let centerX = map.getCenter();
      center={lat:centerX.lat(),lng:centerX.lng()}
      // zoom=zoomLevel
    });


}

let points=[]

let currentStunde=ref(0)
function getStundeForCurrentStunde(){
  switch(currentStunde.value){
    case 0: return "Dienstag, 09:00 - 10:00 Uhr"
    case 1: return "Dienstag, 10:00 - 11:00 Uhr"
    case 2: return "Dienstag, 11:00 - 12:00 Uhr"
    case 3: return "Dienstag, 12:00 - 13:00 Uhr"
    case 4: return "Dienstag, 13:00 - 14:00 Uhr"
    case 5: return "Dienstag, 14:00 - 15:00 Uhr"
    case 6: return "Dienstag, 15:00 - 16:00 Uhr"
    case 7: return "Dienstag, 16:00 - 17:00 Uhr"
    case 8: return "Dienstag, 17:00 - 18:00 Uhr"
    case 9: return "Dienstag, 18:00 - 19:00 Uhr"

    case 10: return "Mittwoch, 09:00 - 10:00 Uhr"
    case 11: return "Mittwoch, 10:00 - 11:00 Uhr"
    case 12: return "Mittwoch, 11:00 - 12:00 Uhr"
    case 13: return "Mittwoch, 12:00 - 13:00 Uhr"
    case 14: return "Mittwoch, 13:00 - 14:00 Uhr"
    case 15: return "Mittwoch, 14:00 - 15:00 Uhr"
    case 16: return "Mittwoch, 15:00 - 16:00 Uhr"
    case 17: return "Mittwoch, 16:00 - 17:00 Uhr"
    case 18: return "Mittwoch, 17:00 - 18:00 Uhr"
    case 19: return "Mittwoch, 18:00 - 19:00 Uhr"
  }
}
function walkCurrentStunde(anz){
  currentStunde.value += anz
  addPoints()

  if(currentStunde.value > 19){
    currentStunde.value=0;
  }

  if(currentStunde.value < 0){
    currentStunde.value=19;
  }
}
async function addPoints(){

  console.log("google--------")
  console.log(google)
  if(google==null){
    console.log("INITIALISIERE GOOGLE")
    google = await gmapsInit()

    

  }else{
    console.log("INITIALISIERE GOOGLE NICHT NEU")
  }
  points= [];
  web.post("/scansForHeatMap",{},function(data){
    // console.log(data);
    

  for (let i = 0; i < data.besucher.length; i++) {
      const besucher = data.besucher[i];
      // console.log(besucher.besucher);
      let anzahl=besucher.besucher[currentStunde.value]
      let coordinates=besucher.coordinates[0]
      // console.log(anzahl+ ": coordinates",coordinates);
      for (let j = 0; j < anzahl; j++) {
        
        points.push(
            new google.maps.LatLng(coordinates.lat, coordinates.lng        )
      )
        }
    }

    // return;
    // for (let i = 0; i < data.scans.length; i++) {
    //   const scan = data.scans[i];
    //   if(typeof(scan.coordinates)=="undefined") continue;
    //   points.push(
    //     new google.maps.LatLng(scan.coordinates.lat, scan.coordinates.lng        )
    //   )
    // }
    // console.log("points")
    // console.log(points)
    initMap()
  })

}


 
    return {
      initMap,
      heatmap,
      addPoints,
      currentStunde,
      walkCurrentStunde,
      getStundeForCurrentStunde
    };
  }
}
</script>

<style scoped>
#map {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

</style>