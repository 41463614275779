<template>
  <div>
    <button class="btn btn-default" @click="createPDF()">PDF erstellen</button>


  </div>
</template>

<script>
import {  ref } from "vue";
import jsPDF from 'jspdf'
export default ({
  name: 'Printer',
  
  components: {
  },
  setup() {

  function getImgUrl(nummer){
    return "/tische/Tisch "+nummer+".png";
  }


  let tische =  ref([1,2,3,4,5,6,7,8,9,20,21,22,23,24,25,30,31,32,33,34,35,36,37,38,39,40,41,42,350,400]);


//http://raw.githack.com/MrRio/jsPDF/master/docs/module-addImage.html#~addImage
function createPDF () {

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [125 , 168]
  });

  for(let tischnummer of [1,2,3,4,5,6,7,8,9,20,21,22,23,24,25,30,31,32,33,34,35,36,37,38,39,40,41,42,350,400]){
  // for(let tischnummer of [1]){


    doc.addImage("/background.jpg", "JPG", 10,10, 105, 148);
    doc.addImage("/lichtenberg_logo_1.png", "PNG", 80,15, 20, 8);
    doc.addImage("/tische/Tisch "+tischnummer+".png", "PNG", 27,33, 72,72);
    doc.setFontSize(8)
    // doc.text("Gasthaus Lichtenberg", 14, 102,{maxWidth:100});
    doc.text("Tisch "+tischnummer, 57, 109,{maxWidth:100});
    if(tischnummer<400)
      doc.addPage();
  }
    doc.save("Aufsteller Lichtenberg.pdf");

  }

    return {
      tische,
      getImgUrl,
      createPDF
    }
  }
});
</script>

<style>


.ueberschrift{
  text-align:center;
  font-weight:bold;
  margin-top:20px;
}
.tisch{
  border:1px solid black;
  background-color:#FFF;
  width:128mm;
  height:180mm;
  float:left;
  margin:5px;
  page-break-before: always 
}

.qrcode{
  width:440px;
  margin-left:20px;
}
</style>