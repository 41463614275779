<template>
  <div>
  



   <QRCodeVue3
          value="Simple QR code"
        />
        <div style="height:200px">dasds</div>

   <QRCodeVue3
          :width="500"
          :height="500"
          value="https://format-c.info/1"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
          :dotsOptions="{
            type: 'dots',
            color: '#ea0a8e',
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: '#ea0a8e' },
                { offset: 1, color: '#ea0a8e' },
              ],
            },
          }"
          :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'rounded', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#ea0a8e' }"
          fileExt="png"
          :download="true"
          myclass="my-qur"
          imgclass="img-qr"
          downloadButton="my-button"
          :downloadOptions="{ name: 'vqr', extension: 'png' }"
        />



        
   <QRCodeVue3
          :width="500"
          :height="500"
          value="https://format-c.info/2"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
          :dotsOptions="{
            type: 'square',
            color: '#ea0a8e',
          }"
          :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'rounded', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#ea0a8e' }"
          fileExt="png"
          :download="false"
          myclass="my-qur"
          imgclass="img-qr"
          
          :downloadOptions="{ name: 'vqr', extension: 'png' }"
        />
  </div>
</template>

<script>
// https://www.npmjs.com/package/qrcode-vue3
import QRCodeVue3 from "qrcode-vue3";
export default ({
  name: 'QrCaptureExample',
  components: {
    QRCodeVue3
  },
  setup() {


    return {
    }
  }
});
</script>